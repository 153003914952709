angular.module('MyHippoCommons.Directives').directive('stateAnnouncementModal', function () {
    return {
        scope: {},
        restrict: 'E',
        transclude: true,
        templateUrl: '/modals/state-announcement/state-announcement.html',

        controller: function ($rootScope,$window, $scope, $location, $mdDialog,$timeout, $state, FlagshipService) {
            const {
                FLAGSHIP_ANNOUNCEMENTS_CAMPAIGN_ID
            } = window.appConfig;

            $scope.generateContactUsLink = () => {
                const protocol = $location.protocol();
                const reactProducerDomian = window.REACT_PRODUCER_DOMAIN;
                const subdomain = $window.location.host.split('.')[0];
                return `${protocol}://${subdomain}.${reactProducerDomian}/contact-us`;
            };
            $scope.contactUsLink = $scope.generateContactUsLink();
            const StateData = {
                newStateAnnouncementNC: {
                    modals: [
                        {
                            image: '../../img/nc_announcement.svg',
                            buttonLabel: 'Next',
                        },
                        {
                            title: 'Welcome, North Carolina',
                            overRideLinks: [
                                {text: 'HO3', link: `${window.appConfig.RESOURCE_CENTER_URL}document/north-carolina-qrg-ho3/`},
                            ],
                            pageContent: '<h4>North Carolina is the 39th state where proactive homeowners can crush home ownership.</h4>' +
                                ' <ul>' +
                                '  <li>Now NC customers can land a policy with free smart home sensors and Hippo Home Care.</li>' +
                                ' <li> We offer <a href="" target="_blank">HO3</a> and HO6 policies to help grow your NC business.</li>' +
                                '<li> Read more in our <a href="http://hippo.com/blog/hippo-greets-north-carolina" target="_blank">blog post on North Carolina.</a></li>' +
                                '</ul>',
                            buttonLabel: 'Next',
                        },
                        {
                            title: 'Welcome, North Carolina',
                            overRideLinks: [
                                {text: 'Contact', link: $scope.contactUsLink},
                                {text: 'Resource Center', link: window.appConfig.RESOURCE_CENTER_URL }
                            ],
                            pageContent: '<h4>Ready to grow your business in North Carolina?</h4>' +
                                '<p><a target="_blank">Contact</a> our Southeast Territory Manager for additional details.</p> <br>' +
                                '<p>Don’t forget to <a href="https://calendly.com/justinehilton/hippo-overview-intro-training?month=2022-06" target="_blank">sign up for training</a> and explore our <a href="" target="_blank">Resource Center</a> for all Hippo related materials.</p>',
                            buttonLabel: 'Close',
                        },
                    ]
                },
                newStateAnnouncementMA: {
                    modals: [
                        {
                            image: '../../img/ma_announcement.svg',
                            buttonLabel: 'Next',
                        },
                        {
                            title: 'Welcome, Massachusetts',
                            overRideLinks: [
                                {text: 'HO3', link: `${window.appConfig.RESOURCE_CENTER_URL}document/massachusetts-qrg-ho3/`},
                            ],
                            pageContent: '<h4>Massachusetts is the 40th state where proactive homebuyers can crush home ownership.</h4>' +
                                ' <ul>' +
                                '  <li>Now MA customers can get a new level of protection with complimentary smart home sensors and Hippo Home Care.</li>' +
                                ' <li>We offer <a target="_blank">HO3</a> and HO6 policies to help grow your MA business.</li>' +
                                '<li>Read more in our <a target="_blank" href="http://hippo.com/blog/hippo-greets-massachusetts">blog post on Massachusetts.</a></li>' +
                                '</ul>',
                            buttonLabel: 'Next',
                        },
                        {
                            title: 'Welcome, Massachusetts',
                            overRideLinks: [
                                {text: 'Contact', link: $scope.contactUsLink},
                                {text: 'Resource Center', link: window.appConfig.RESOURCE_CENTER_URL }
                            ],
                            pageContent: '<h4>Ready to grow your business in Massachusetts?</h4>' +
                                '<p><a target="_blank">Contact</a> our Northeast Territory Manager for additional details.</p> <br>' +
                                '<p>Don’t forget to <a href="https://calendly.com/justinehilton/hippo-overview-intro-training?month=2022-06" target="_blank">sign up for training</a> and explore our <a href="" target="_blank">Resource Center</a> for all Hippo related materials.</p>',
                            buttonLabel: 'Close',
                        },
                    ]
                },
                oneTimePaymentAnnouncement: {
                    modals: [
                        {
                            title: '<span class="fa fa-star stars"/><span class="fa fa-star stars"/><span class="fa fa-star stars"/><span class="green"><strong>&nbsp;New Feature Alert</strong></span><span class="fa fa-star stars"/><span class="fa fa-star stars"/><span class="fa fa-star stars"/>',
                            pageContent: '<h2 class="green"><strong>One-Time Payment</strong></h2><h2 class="green"><strong>Claims Tracking</strong></h2><br>' +
                                '<p>Hippo is thrilled to announce two new Producer Portal features based on your feedback.  One-Time Payments and Claims are now available.</p> <br>',
                            buttonLabel: 'Next',
                        },
                        {
                            title: '<span class="green"><strong>One-Time Payment</strong></span>',
                            pageContent: '<p>The <span class="green">One-Time Payment</span> feature makes it simple to make a payment on a policy.  Pay renewals and existing balances due for your customer without contacting Agent Support.</p>' +
                                '<p>Available via the new Billing section.</p> <br>',
                            buttonLabel: 'Next',
                        },
                        {
                            title: '<span class="green"><strong>Claims Tracking</strong></span>',
                            pageContent: '<p>Locate and track claims for your customers.  The new <span class="green">Claims</span> feature that provides claim type, filed date, status, payout details, adjuster name, and contact information are now immediately available for your agency.' + 
                                '<p>Available via the new Claims section.</p>' +
                                '<p>Contact your Account Manager at 800-659-4226 ext 6012 or <a href="https://calendly.com/nfavia" target="_blank">Schedule a Call</a> to learn more.</p>',
                            buttonLabel: 'Close',
                        },
                    ]
                }
            };

            $scope.directionForDots = 'backward';
            $scope.shouldShowAnnouncementModal = false;
            $scope.modalsToDisplay = [];
            //test to see if current state (UI-view) is displaying something with a list (meaning we are 'most likely' displaying something on the portal)
            const isInPortal = $state &&
                $state.current &&
                $state.current.templateUrl &&
                $state.current.templateUrl.includes('list');
            // add logic to get Flagship
            let flagshipShowModal = false;
            FlagshipService.getCampaignData({campaignID: FLAGSHIP_ANNOUNCEMENTS_CAMPAIGN_ID}).then((res)=>{
                const data = res.data &&
                        res.data.variation &&
                        res.data.variation.modifications &&
                        res.data.variation.modifications.value;
                const stateAnnouncementsTurnedOn = Object.entries(data).filter(([flagShipKey, flagShipValue]) =>
                    flagShipValue && Object.keys(StateData).includes(flagShipKey));
                if(stateAnnouncementsTurnedOn.length < 1){
                    flagshipShowModal = false;
                }
                else{
                    // we only support one state announcement at a time, take first one, and then take the key which is in index zero
                    $scope.announcementState = stateAnnouncementsTurnedOn[0][0];
                    flagshipShowModal = true;
                }
                const hasSeenAnnouncementModal = ($window.localStorage.getItem(`hasSeen${$scope.announcementState}Modal`) === 'true');
                $scope.shouldShowAnnouncementModal = flagshipShowModal && !hasSeenAnnouncementModal && isInPortal;
                $scope.modalsToDisplay = $scope.shouldShowAnnouncementModal ? StateData[$scope.announcementState].modals : [];
                return $scope.modalsToDisplay;
            });

            $scope.parentClose = function () {
                // add to local storage that we have seen this,
                $window.localStorage.setItem(`hasSeen${$scope.announcementState}Modal`, 'true');
                $scope.shouldShowAnnouncementModal = false;
                $mdDialog.cancel();
            };


        },
    };
});
